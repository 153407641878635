Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleGetAPiMethod = "GET";
exports.exampleDeleteApiMethod = "DELETE"
exports.exampleApiContentType = "application/json";
exports.exampleFormData = "multipart/form-data"
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "slideshowcreator2";
exports.labelBodyText = "slideshowcreator2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.createSlideShowApiEndPoint = "bx_block_slideshowcreator2/slide_shows"
exports.getSlideShowApiEndPoint = "bx_block_slideshowcreator2/slide_shows"
exports.deleteSlideShowAttachmentIdApiEndPoint = "bx_block_slideshowcreator2/slide_shows/"
// Customizable Area End