import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { createRef } from "react";
import ImagePicker from "react-native-image-crop-picker";
export interface VisualSearchItem {
  url: string;
}

interface VisualSearchData {
  similar_images: Array<VisualSearchItem>;
}
export interface SelectedImageItem {
  uri: string;
  type: string;
  name?: string;
}
interface ApiCallInterface {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}
interface SimilarImage {
  url: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  uploadFile:File|string;
  selectedFiles: File[];
  fileURLs: string[];
  imageUrls: SimilarImage[];
  selectedImage: Array<SelectedImageItem>;
  visualSearchData: Array<VisualSearchItem>;
  isVisualLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Visualsearch2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef:React.RefObject<HTMLInputElement>=createRef<HTMLInputElement>();
  visualSearchDataApi:string="";
  visualSearchApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      uploadFile:"",
      selectedFiles: [],
      fileURLs: [],
      imageUrls: [],
      selectedImage: [],
      visualSearchData: [],
      isVisualLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        if (apiRequestCallId === this.visualSearchDataApi) {
          this.setState({imageUrls:responseJson.similar_images})
        }
      }
      this.visualSearchApiDataCallFunction(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    // Customizable Area Start
    let messages = new Message(getName(MessageEnum.AccoutLoginSuccess));
    messages.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(messages);
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleSelectDoc = () => {
    const fileInput = this.fileInputRef.current;
  if (fileInput) {
    fileInput.click();
  }
  }

  handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      await new Promise<void>((resolve) => {
        this.setState({ uploadFile: file }, resolve);
      });

      const fileURL = URL.createObjectURL(file);
      this.setState({
        fileURLs: [fileURL],
      });

      const formData = new FormData();
    if(this.state.uploadFile){
      formData.append('image',this.state.uploadFile)
    }

      this.visualSearchDataApi = await this.VisualSearchApiCall({
        method: "POST",
        body: formData,
        type: "formData",
        endPoint: "bx_block_visualsearch2/search",
      });
      
    }
  };
  
  


  VisualSearchApiCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body, type } = data;
  
    const header = {
      "Content-Type": contentType
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  };

  onPressPickImage = async () => {
    let imageArray: Array<SelectedImageItem> = [];
    let image = ImagePicker.openPicker({
      multiple: false,
      mediaType: "photo",
      compressImageQuality: 0.3,
      includeBase64: true,
      cropping: true
    }).then(async (image) => {
      let filename = image.path.substring(image.path.lastIndexOf('/') + 1)
      const file: SelectedImageItem = {
        uri: image.sourceURL ? image.sourceURL : image.path,
        type: image.mime,
        name: filename ? filename : ""
      };
      imageArray.push(file)
      this.setState({ selectedImage: imageArray }, () => this.visualSearch());
    });
    return image;
  };

  showImageRenderFunction = () => {
    if (this.state.selectedImage?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  showImageFunction = (imgItem: SelectedImageItem) => {
    if (imgItem.uri) {
      return imgItem.uri;
    }
  };

  noDataFound = () => {
    if (this.state.visualSearchData.length == 0 && this.state.selectedImage.length > 0 && !this.state.isVisualLoading)
      return configJSON.noDataText;
  };

  visualSearchApiDataCallFunction = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdObj = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseObj = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallIdObj && responseObj) {
        if (apiRequestCallIdObj === this.visualSearchApiCallId) {
          this.visualSearchSuccessCallBack(responseObj);
        }
      }
    }
  };

  visualSearch = async () => {
    this.setState({isVisualLoading: true});
    const formData = new FormData();
    this.state.selectedImage.forEach((file) => {
      const fileData = {
        uri: file.uri,
        type: file.type,
        name: file.name
      };
      formData.append(`image`, fileData as unknown as Blob);
    });
    this.visualSearchApiCallId = await this.VisualSearchApiCall({
      contentType: configJSON.exampleFormData,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.visualSearchAPIEndPoint,
      body: formData,
      type: "formData"
    });
  };

  visualSearchSuccessCallBack = (responseJson: VisualSearchData) => {
    this.setState({ isVisualLoading: false, visualSearchData: responseJson.similar_images });
  };
  // Customizable Area End
}
