import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as React from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean;
  loginData: {
    email: string
    password: string
  };
  formError: {
    email: string
    password: string
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validateAccountId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showPassword: false,
      loginData: {
        email: '',
        password: '',
      }, 
      formError: {
        email: '',
        password: '',
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    ) : (
      <Visibility
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    );
  };
  submitLoginDetails = (email: string, password: string) => {
  }

  handleNavigateSignup = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  handleLoginBtn = () =>{
  }

  handleClickShowPassword = () =>
  this.setState({
    showPassword: !this.state.showPassword
  });
  // Customizable Area End
}
