import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { createRef } from "react";

export interface SelectedFile {
  fileCopyUri: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

interface UrlInfo {
  url: string;
  type: string;
  size: number;
  attachment_id: number;
}

interface UrlsResponse {
  urls: UrlInfo[];
}

export interface Attachment {
  url: string;
  type: string;
  size: number;
  attachment_id: number;
  id: number;
}

export interface SlideShow {
  type: string;
  id: number;
  attachment_urls: Array<Attachment>;
}

interface SlideShowsResponse {
  slide_shows: SlideShow[];
}

interface FileUrl {
  url: string;
  type: string;
}

interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;

}


interface ApiCallInterface {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}

export interface AttachmentUrl{
  url:string;
  type:string;
  size:number;
  attachment_id:number;
}

export interface SlideShowInterface{
  id:number;
  attachment_urls:AttachmentUrl[]
}

export interface FileUrlsInterface{
 url:string,
 type:string,
}

let intervalId: string | number | NodeJS.Timeout | undefined | null;

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedFiles: Array<SelectedFile>;
  currentImageIndex: number;
  modalVisible: boolean;
  isPageRefreshed: boolean;
  slideShowData: Array<UrlInfo>;
  slideShowItems: Array<Attachment>;
  slideShowData2:[],
  uploadFile:File|string,
  uploadFilesData:FileUrlsInterface[],
  fileAttachments:AttachmentUrl,
  slideShowId:string,
  attachmentId:string,
  currentFileIndex:number,
  showSlideshow:boolean,
  pdfVisible: boolean;
  selectedAttachmentId: Attachment;
    // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Slideshowcreator2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  maxFileSize = 10000000;
  createSlideShowApiCallId: string = "";
  getSlideShowDataApiCallId: string = "";
  deleteslideShowAttachmentApiCallId: string = "";
 // apiGetAllSlideShowId: string;
  slideShowDataApi:string="";
  uploadFilesApi:string="";
  deleteAttachmentApi:string="";
  fileInputRef:React.RefObject<HTMLInputElement>=createRef<HTMLInputElement>();;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedFiles: [],
      currentImageIndex: 0,
      modalVisible: false,
      isPageRefreshed: true,
      slideShowData: [],
      slideShowItems: [],
    slideShowData2:[],
    uploadFile:"",
    uploadFilesData:[],
    fileAttachments:{
      url: "",
      type: "",
      size: 0,
      attachment_id: 0
    },
    slideShowId:"",
    attachmentId:"",
    currentFileIndex: 0,
    showSlideshow:false,
    pdfVisible: false,
    selectedAttachmentId: {
      url: "",
      type: "",
      size: 0,
      attachment_id: 0,
      id: 0
    }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        if (apiRequestCallId === this.createSlideShowApiCallId) {
          this.createSlideShowSuccessResponse(responseJson);
        } else if (apiRequestCallId === this.getSlideShowDataApiCallId) {
          this.getSlideShowSuccessResponse(responseJson);
        } else if (apiRequestCallId == this.deleteslideShowAttachmentApiCallId) {
          this.deleteslideShowAttachmentSuccessResponse();
        }
        else  if (apiRequestCallId === this.slideShowDataApi) {
          this.setState({slideShowData2:responseJson.slide_shows})
          this.fetchFileURLs(responseJson.slide_shows)
        }else if(apiRequestCallId === this.uploadFilesApi){
          this.getAllSlideshows();
        }else if(apiRequestCallId === this.deleteAttachmentApi){
          this.getAllSlideshows();
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async  componentDidMount(): Promise<void> {
    this.getAllSlideshows();
    this.getSlideShow()
  }



  doCreatePressed = () => {
    if (this.state.slideShowItems.length == 0) {
      this.closeModal();
    } else {
      this.openModal();
    }
  };
  
  btnSelectDocProps = {
    onPress: () => this.doSelectDocPressed(),
  };

  doSelectDocPressed = async () => {
    try {
      const results = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.pdf, DocumentPicker.types.images],
        allowMultiSelection: true,
        copyTo: "cachesDirectory",
      });
      const tempFiles: Array<SelectedFile> = results.map((result: DocumentPickerResponse) => ({
        fileCopyUri: result?.fileCopyUri?.replace("file:/", "file:///") || "",
        name: result.name || "",
        size: result.size || 0, 
        type: result.type || "", 
        uri: result.uri || "",
      }));
      this.setState({ selectedFiles: tempFiles }, ()=> this.createSlideShow());
    } catch (error) {
      this.setState({isPageRefreshed: false});
    }
  };
 
   closeModal = () => {
    this.setState({ modalVisible: false });
  };

   openModal = () => {
    this.setState({ modalVisible: true });
  };

  _keyExtractor = (item: object, index: number) => {
    return index?.toString();
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = data
    const header = {
      "Content-Type": contentType,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  createSlideShow = async () => {
    const formData = new FormData();
    this.state.selectedFiles.forEach((file, fileKey) => {
      const fileData = {
        uri: file.uri || file.fileCopyUri,
        type: file.type,
        name: file.name,
      };
      formData.append(`files[${fileKey}]`, fileData as unknown as Blob);
    });
    this.createSlideShowApiCallId = await this.apiCall({
      contentType: configJSON.exampleFormData,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createSlideShowApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  createSlideShowSuccessResponse = (responseJson: UrlsResponse) => {
    this.setState({slideShowData: responseJson.urls},()=>this.getSlideShow())
  };

  getSlideShow = async () => {
    this.setState({isPageRefreshed: true})
    this.getSlideShowDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleGetAPiMethod,
      endPoint: configJSON.createSlideShowApiEndPoint
    });
  };

  getSlideShowSuccessResponse = (responseJson: SlideShowsResponse) => {
    const attachments: Array<Attachment> = [];
    responseJson.slide_shows.forEach((slideShow: SlideShow) => {
        slideShow.attachment_urls.forEach((attachment: Attachment) => {
            attachments.push({
                url: attachment.url,
                type: attachment.type,
                size: attachment.size,
                attachment_id: attachment.attachment_id,
                id: slideShow.id
            });
        });
    });
    this.setState({ slideShowItems: attachments });
    if (attachments.length > 0) {
      const firstAttachment = attachments[0];
      const selectedAttachmentId = {
          url: firstAttachment.url,
          type: firstAttachment.type,
          size: firstAttachment.size,
          attachment_id: firstAttachment.attachment_id,
          id: firstAttachment.id
      };
      this.setState({ selectedAttachmentId });
  }
};

deleteSlideShowAttachment = async (attachment_id: number, slideShowDataId:number) => {
  this.deleteslideShowAttachmentApiCallId = await this.apiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.exampleDeleteApiMethod,
    endPoint: `${configJSON.deleteSlideShowAttachmentIdApiEndPoint}${slideShowDataId}?attachment_id=${attachment_id}`
  });
};

deleteslideShowAttachmentSuccessResponse = () => {
  alert("Attachment deleted successfully")
  this.getSlideShow()
};
  

  handleSelectDoc = () => {
    const fileInput = this.fileInputRef.current;
  if (fileInput) {
    fileInput.click();
  }
  }

 

  apiCall2 = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type !== "formData"
    ? request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body)
     
    runEngine.sendMessage(request.id, request);
    return request.messageId;
    };

  getAllSlideshows = async () => {
    this.slideShowDataApi = await this.apiCall2({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_slideshowcreator2/slide_shows",
    });
  };

  handleUploadFile = async (event:React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if(files){
    const file = files[0];
    await new Promise<void>((resolve) => {
      this.setState({ uploadFile: file }, resolve);
    });
    const formData = new FormData();
    if(this.state.uploadFile){
      formData.append("files[0]",this.state.uploadFile)
    }
    
    this.uploadFilesApi = await this.apiCall2({
      method: "POST",
      body:formData,
      type:"formData",
      endPoint: "bx_block_slideshowcreator2/slide_shows",
    });
  }
}



  fetchFileURLs = (slidesShows:SlideShowInterface)=>{
    const fileURLs: FileUrlsInterface[] = [];

   Array.isArray(slidesShows) && slidesShows.forEach((slideshow)=>{
    slideshow.attachment_urls.forEach((attachment:AttachmentUrl)=>{
      fileURLs.push({url:attachment.url,
        type:attachment.type
      })
    })
   })

   this.setState({uploadFilesData:fileURLs})
  }

  deleteAttachedFile = (file:AttachmentUrl,slideShowId:number)=>{
    this.setState({
      slideShowId:String(slideShowId),
      attachmentId:String(file.attachment_id)
    })
    this.deleteFileApiCall()
  }

  deleteFileApiCall = async () => {
    const {slideShowId,attachmentId} = this.state;
    this.deleteAttachmentApi = await this.apiCall2({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `bx_block_slideshowcreator2/slide_shows/${slideShowId}?attachment_id=${attachmentId}`,
    });
    this.getAllSlideshows()

  };

  handlePrevSlide = () => {
    this.setState((prevState)=>({
      currentFileIndex:prevState.currentFileIndex - 1
    }))
  };

  handleNextSlide = () => {
    this.setState((prevState)=>({
      currentFileIndex: prevState.currentFileIndex===prevState.uploadFilesData.length-1 ? 0 : prevState.currentFileIndex + 1
    }))
  };

  handleShowSlideshow = ()=>{
    if(this.state.slideShowData2?.length>0){
      this.setState({
        showSlideshow:true,
      })
      this.startSlider()
    }
  }

  startSlider=()=> {
    intervalId = setInterval(this.handleNextSlide, 3000);
  }

  handleMouseEnter=()=>{
    if(intervalId){
      clearInterval(intervalId);
      intervalId=null;
    }
  }

  handleMouseOut=()=>{
    if(!intervalId){
      this.startSlider()
    }
  }

  closePdfModal = () => {
    this.setState({ pdfVisible: false });
  };

  pdfModal = (slideShowValue: Attachment)=> {
    this.setState({ pdfVisible: true, selectedAttachmentId: slideShowValue, isPageRefreshed: true });
  };

  loadPdfImage = () => {
    this.setState({ isPageRefreshed: false });
  };
  // Customizable Area End
}
