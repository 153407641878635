import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { Logo, loginImage } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
// Customizable Area End

import LoginController, {
  Props,
} from "./LoginController.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";


export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginStyle>
        <Box className="mainContainer">
          <Box className="formContainer">
            <Box className="imageLogo">
              <img src={Logo} alt="Logo" />
            </Box>
            <Formik
              data-test-id="formikForm"
              initialValues={{
                email: this.state.loginData.email || "",
                password: this.state.loginData.password || "",
              }}
              onSubmit={values => {
                this.submitLoginDetails(values.email, values.password);
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <form className="formContainer" onSubmit={handleSubmit}>
                  <Typography className="loginTitle">Welcome back!</Typography>
                  <Typography className="textFieldTitles">E-mail</Typography>
                  <TextField
                    className="placeholderTexts"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    autoComplete="off"
                    name="email"
                    placeholder="Your e-mail"
                    variant="outlined"
                    fullWidth
                  />
                  <Typography className="textFieldTitles">Password</Typography>
                  <TextField
                    className="placeholderTexts"
                    onChange={handleChange}
                    value={values.password}
                    error={
                      Boolean(touched.password && errors.password) ||
                      Boolean(this.state.formError.password)
                    }
                    helperText={
                      (touched.password && errors.password) ||
                      this.state.formError.password
                    }
                    autoComplete="off"
                    fullWidth
                    placeholder="Password"
                    name="password"
                    type={!this.state.showPassword ? "password" : "text"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            data-test-id="showPasswordIconButton"
                            id="passwordConfirmationInput"
                          >
                            {this.renderIconShowPassword(
                              !this.state.showPassword
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box className="conditionContainer">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="rememberMe"
                          style={{ color: "#557C29" }}
                        />
                      }
                      label={
                        <Typography>
                          <span style={{ color: "#1C2110", fontWeight: 400, fontSize: "14px", lineHeight: "22px", fontStyle: "Poppins" }}>Remember me</span>
                        </Typography>
                      }
                    />
                    <Button className="forgotPasswordBtn">Forgot Password?</Button>
                  </Box>
                  <Button onClick={this.handleLoginBtn} data-test-id="loginBotton" type="submit" className="loginBotton" variant="contained" color="primary" fullWidth >
                    Login
                  </Button>

                  <Box className="accountcontainer">
                    <Typography className="accountTextTitle">Don’t have an account?</Typography>
                    <Button data-test-id="signupBtn" onClick={this.handleNavigateSignup} className="signupBtn">Sign up</Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </LoginStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const LoginStyle = styled("div")({
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  background: `url(${loginImage})`,
  backgroundRepeat: "no-repeat",

  "& .mainContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    marginLeft: "55%",
    zIndex: 0,
    backgroundColor: "white",
    borderRadius: "20px",
    width: "440px",
    overflow: "hidden",
    marginTop: '10%',
    "@media (max-width: 768px)": {
      marginLeft: "5%",
      width: "100%",
    },
    "@media (max-width: 369px)": {
      marginLeft: "0%",
      width: "100%",
    },
  },
  "& .formContainer": {
    width: '100%',
    height: "100%"
  },
  "& .imageLogo": {
    padding: "0px, 0px, 0px, 40px",
    textAlign: "center",
    marginBottom: '32px'
  },
  "& .loginTitle": {
    marginTop: "32px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "32px",
    letterSpacing: "-0.1%",
    color: "#1C2110"
  },
  "& .loginBotton": {
    borderRadius: '8px',
    height: '56px',
    Gap: '8px',
    padding: '16px',
    fontWeight: 700,
    marginTop: '24px',
    lineHeight: "24px",
    fontSize: "16px",
    backgroundColor: '#8CA74B',
  },
  "& .accountcontainer": {
    alignItems: "center",
    display: "flex",
    marginTop: "16px",
    justifyContent: "center",
  },
  "& .textFieldTitles": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#1C2110",
    lineHeight: "24px",
    marginBottom: '4px',
    marginTop: '16px',
    fontFamily: "Poppins",
  },
  "& .placeholderTexts": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#94A3B8"
  },
  "& .conditionContainer": {
    marginTop: '15px',
    display: "flex",
    justifyContent: "space-between"
  },
  "& .forgotPasswordBtn": {
    color: "#557C29",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    "& .MuiButton-label": {
      textTransform: "lowercase",
      fontWeight: 700,
      fontSize: "14px",
    },
  },
  "& .accountTextTitle": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110"
  },
  "& .signupBtn": {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#557C29",
    fontStyle: "Poppins"
  },
});
// Customizable Area End
