import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import * as React from 'react';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPassword: boolean;
  signUpData: {
    fullName: string
    email: string
    password: string
  };
  formError: {
    fullName: string
    email: string
    password: string
  };
  isChecked: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignuploginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validateAccountId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPassword: false,
      signUpData: {
        fullName: '',
        email: '',
        password: '',
      }, 
      formError: {
        fullName: '',
        email: '',
        password: '',
      },
      isChecked: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  validatePassword = [
    {
      regex: configJSON.capital,
      text: "At least one capital letter"
    },
    {
      regex: configJSON.lower,
      text: "At least one lowercase letter"
    },
    {
      regex: configJSON.digit,
      text: "At least one number"
    },
    {
      regex: configJSON.length,
      text: "Minimum character length is 8 characters"
    }
  ];

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({ isChecked: event.target.checked })
  }
  
  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    ) : (
      <Visibility
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    );
  };
  
  signUpSchema = Yup.object({
    fullName: Yup.string()
      .required("Please enter your Full Name"),
    email: Yup.string()
      .email("Invalid e-mail")
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter your password"),
    agreeTerms: Yup.boolean()
      .oneOf([true], "You have to agree with Privacy Policy and Terms and Conditions to Sign up.")
  });

  handleClickShowPassword = () =>
  this.setState({
    showPassword: !this.state.showPassword
  });

  checkValidatePassword = (password: string) => {
    let isValidPassword = true;
    this.validatePassword.forEach((item: { regex: RegExp }) => {
      if (!item.regex.test(password)) {
        isValidPassword = false;
      }
    });
    return isValidPassword;
  };

  submitValidateAccount = (full_name: string, email: string, password: string) => {
    const formData = {
      type: 'email_account',
      attributes: {
        full_name:full_name,
        email:email,
        password:password,
      },
    }
    this.setState({
      signUpData: {
        fullName: full_name,
        email: email,
        password: password,
      },
    })

    const header = {
      "Content-Type": 'application/json',
    };

    const httpBody = {
      formData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  handleNavigateLogin = () => {
    this.props.navigation.navigate("Login")
  }
  // Customizable Area End
}
