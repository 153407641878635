// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filecompression from "../../blocks/filecompression/src/Filecompression";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import FacialTracking from "../../blocks/facialtracking/src/FacialTracking";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import PrivacySettings from "../../blocks/privacysettings/src/PrivacySettings";
import Videos from "../../blocks/videos/src/Videos";
import Servicespecificsettingsadmin from "../../blocks/servicespecificsettingsadmin/src/Servicespecificsettingsadmin";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Download1 from "../../blocks/download1/src/Download1";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Cfdatastoragetracker from "../../blocks/cfdatastoragetracker/src/Cfdatastoragetracker";
import Cfdesigncomplexity from "../../blocks/cfdesigncomplexity/src/Cfdesigncomplexity";
import Cfcloudencryption4 from "../../blocks/cfcloudencryption4/src/Cfcloudencryption4";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Dataencryption2 from "../../blocks/dataencryption2/src/Dataencryption2";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Cffrapi from "../../blocks/cffrapi/src/Cffrapi";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Slideshowcreator2 from "../../blocks/slideshowcreator2/src/Slideshowcreator2";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Subscriptionbilling from "../../blocks/subscriptionbilling/src/Subscriptionbilling";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Visualsearch2 from "../../blocks/visualsearch2/src/Visualsearch2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import Dataencryption from "../../blocks/dataencryption/src/Dataencryption";
import Login from "../../blocks/signuplogin/src/Login.web";


const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Contentmanagement1:{
 component:Contentmanagement1,
path:"/Contentmanagement1"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filecompression:{
 component:Filecompression,
path:"/Filecompression"},
Share:{
 component:Share,
path:"/Share"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
FacialTracking:{
 component:FacialTracking,
path:"/FacialTracking"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
PrivacySettings:{
 component:PrivacySettings,
path:"/PrivacySettings"},
Videos:{
 component:Videos,
path:"/Videos"},
Servicespecificsettingsadmin:{
 component:Servicespecificsettingsadmin,
path:"/Servicespecificsettingsadmin"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Download1:{
 component:Download1,
path:"/Download1"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PasswordStrength:{
 component:PasswordStrength,
path:"/PasswordStrength"},
Signuplogin:{
 component:Signuplogin,
 path:"/Signup"},
 Login:{
  component:Login,
  path:"/Login"},
Cfdatastoragetracker:{
 component:Cfdatastoragetracker,
path:"/Cfdatastoragetracker"},
Cfdesigncomplexity:{
 component:Cfdesigncomplexity,
path:"/Cfdesigncomplexity"},
Cfcloudencryption4:{
 component:Cfcloudencryption4,
path:"/Cfcloudencryption4"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Dataencryption2:{
 component:Dataencryption2,
path:"/Dataencryption2"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Dashboard4:{
 component:Dashboard4,
path:"/Dashboard4"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Cffrapi:{
 component:Cffrapi,
path:"/Cffrapi"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Slideshowcreator2:{
 component:Slideshowcreator2,
path:"/Slideshowcreator2"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Subscriptionbilling:{
 component:Subscriptionbilling,
path:"/Subscriptionbilling"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Visualsearch2:{
 component:Visualsearch2,
path:"/Visualsearch2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
Dataencryption:{
 component:Dataencryption,
path:"/Dataencryption"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
        <View style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;