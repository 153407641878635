import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { SlideShowInterface, FileUrlsInterface} from "./Slideshowcreator2Controller";
import {pdfLogo,imageLogo} from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import {Document, Page, pdfjs} from "react-pdf";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const HeadingBox = styled(Box)({
  display:"flex",
  justifyContent:"center"
})

const ButtonBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  gap:"10px",
  margin:"10px 0px 10px 0px",
})

const SelectDocButton = styled(Button)({
  backgroundColor:"lightgreen",
  color:"white",
  '&:hover': {
    backgroundColor: 'green',
      },
})

const UploadedAttachmentsBox = styled(Box)({
  display:"flex",
  flexWrap:"wrap"
})

const LogoAndCrossIcon = styled(Box)({
  margin:"0px 5px 0px 8px"
})

const FileTypeImage = styled("img")({
  width:"60px",
  height:"60px",
  marginBottom:"5px",
})

const CrossIconImage = styled(CloseIcon)({
  width:"20px",
  height:"20px",
  position:"absolute",
  marginRight:"5px",
  cursor:"pointer",
})

const PlaySlideShowButton = styled(Button)({
  backgroundColor:"grey",
  color:"white",
  '&:hover': {
    backgroundColor: 'brown',
  },
})

const SlideButtonBox = styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"flex-end"
})

const SlideShowBox = styled(Box)({
  marginBottom:"30px"
})

const SlideshowImageBox = styled(Box)({
  display:"flex",
  justifyContent:"center"
})
const SlideshowImage = styled("img")({
  width: '350px',
  height:"350px"
})

const NextSlideIcon = styled(ChevronRightIcon)({
  color:"black",
  fontSize:"40px",
  cursor:"pointer",
})


const PrevSlideIcon = styled(ChevronLeftIcon)({
  color:"black",
  fontSize:"40px",
  cursor:"pointer",
})

const CustomInput = styled("input")({
  display:"none",
})



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Customizable Area End

import Slideshowcreator2Controller, {
  Props,
  configJSON,
} from "./Slideshowcreator2Controller";

export default class Slideshowcreator2 extends Slideshowcreator2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <HeadingBox><h3>Slideshowcreator2</h3></HeadingBox>
        <ButtonBox>
        <CustomInput
              type="file"
              data-test-id = "fileInput1"
              accept=".pdf,.jpg,.jpeg,.png"
              multiple
              ref={this.fileInputRef}
              onChange={this.handleUploadFile}
        />
        <SelectDocButton data-test-id="upload-btn" onClick={this.handleSelectDoc}>SELECT DOC</SelectDocButton>
        {<UploadedAttachmentsBox>
          {this.state.slideShowData2.map((slideshow:SlideShowInterface,index:number)=>{
          return slideshow.attachment_urls.map((file)=>{
            if(file.type === "image/jpeg" || file.type === "image/png"){
              return<LogoAndCrossIcon key={`image_${file.attachment_id}`}>
                <FileTypeImage src={imageLogo}/>
                <CrossIconImage data-test-id="crossIconImage"  onClick={()=>this.deleteAttachedFile(file,slideshow.id)}/>
               </LogoAndCrossIcon>
            }else if(file.type === "application/pdf"){
              return<LogoAndCrossIcon key={`pdf_${file.attachment_id}`}>
              <FileTypeImage src={pdfLogo}/>
              <CrossIconImage data-test-id="crossIconPdf" onClick={()=>this.deleteAttachedFile(file,slideshow.id)}/>
             </LogoAndCrossIcon>
            }
          })
          })}
        </UploadedAttachmentsBox>}
        <PlaySlideShowButton data-test-id="playSlideBtn" onClick={this.handleShowSlideshow}>PLAY SLIDESHOW</PlaySlideShowButton>
        </ButtonBox>
        {this.state.showSlideshow && this.state.slideShowData2.length>0  && <SlideButtonBox>
          {this.state.currentFileIndex !== 0 && <PrevSlideIcon data-test-id="prevSlideBtn" onClick={this.handlePrevSlide}/>}
          {this.state.currentFileIndex !== this.state.uploadFilesData.length-1  && <NextSlideIcon data-test-id="nextSlideBtn" onClick={this.handleNextSlide}/>}
        </SlideButtonBox>}

        {this.state.showSlideshow && (
            <SlideShowBox>
              <div>
                {this.state.uploadFilesData.map((file: FileUrlsInterface, index: number) => {
                  let content = null;
                  if (file.type === "image/png" || file.type === "image/jpeg") {
                    content = (
                      <SlideshowImageBox data-test-id="slidebox" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseOut}>
                        <SlideshowImage src={file.url}  />
                      </SlideshowImageBox>
                    );
                  } else if (file.type === "application/pdf") {
                    content = (
                      <Document file={file.url}>
                        <Page pageNumber={1} />
                      </Document>
                    );
                  }
                  return index === this.state.currentFileIndex && content;
                })}
              </div>
            </SlideShowBox>
          )}
        
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
