Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualsearch2";
exports.labelBodyText = "visualsearch2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.searchImageText = "Search Image"
exports.exampleFormData = "multipart/form-data"
exports.visualSearchAPIEndPoint = "bx_block_visualsearch2/search"
exports.noDataText = "No Images Found"
// Customizable Area End