import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { galleryIcon } from './assets';


const CustomInput = styled("input")({
  display: "none",
})
const SelectDocButton = styled(Button)({
  backgroundColor: "lightgreen",
  marginTop: 10,
  color: "white",
  '&:hover': {
    backgroundColor: 'green',
  },
})


// Customizable Area End

import Visualsearch2Controller, {
  Props,
  configJSON,
} from "./Visualsearch2Controller";

export default class Visualsearch2 extends Visualsearch2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        <Box sx={webStyle.container}>
          <img src={galleryIcon} alt="My Image" style={webStyle.iconSize} />
          <Typography style={webStyle.uploadText}>
            Upload Image
          </Typography>
          <CustomInput
            type="file"
            data-test-id="fileInput1"
            accept=".pdf,.jpg,.jpeg,.png"
            multiple
            ref={this.fileInputRef}
            onChange={this.handleUploadFile}
          />
          <SelectDocButton data-test-id="upload-btn" onClick={this.handleSelectDoc}>
            SELECT IMAGE
          </SelectDocButton>
          {this.state.fileURLs.map((fileURL, index) => (
            <div key={index}>
              <img src={fileURL} alt={`Selected file ${index}`} style={webStyle.selectedFile} />
            </div>
          ))}


          <Box>
            <div style={{ maxHeight: '500px', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {this.state.imageUrls.map((imageUrl, index) => (
                <Box key={index} style={{ width: '80%', height: 'auto'}}>
                  <img src={imageUrl.url} alt={`Image ${index}`} style={webStyle.selectedFile} />
                </Box>
              ))}
            </div>
          </Box>
        </Box>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center vertically
    paddingBottom: "30px",
    background: "#fff"
  },
  container: {
    textAlign: "center",
  },
  iconSize: {
    width: 50,
    height: 50,
    marginTop:30
  },
  uploadText: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginTop: 20
  },
  selectedFile: {
    width: "30%",
    height: "auto",
    marginTop: 20,
  }
};
// Customizable Area End
