import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  price: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type:string, filename:string };
  selectedCategory:any;
  allCategories: { value: string, label: string}[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: {data:null, content_type:"", filename:""},
      selectedCategory: {},
      allCategories: []
    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
        this.getAllCategory();
        this.getPostData();
      });

    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const item = message.getData(getName(MessageEnum.PostDetailDataMessage));
      if (item) {
        this.setState({
          name: item.attributes.name,
          description: item.attributes.description,
          price: item.attributes.price?.toString(),
          currency: "$",
          category_id: item.attributes.category_id,
          id: item.attributes.id,
          profileImageData: {
            data: item.attributes.product_image,
            content_type: "image/jpeg",
            filename: "image.jpeg"
          },
          image: ""
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (apiRequestCallId === this.apiPostItemCallId) {
          this.setState({ PostData: responseJson.data, refresh: false });
        } else if (apiRequestCallId === this.addpostApiCallId) {
          this.setState({ refresh: true });
          alert(configJSON.PostCreated)
          this.props.navigation.goBack();
        } else if (apiRequestCallId === this.apiGetCategoryCallID) {
          var allCategories: {value: string, label: string}[] = [];
          let categories: string[] = [];

          const posts = responseJson.data;

          posts.forEach((item: {attributes: { name:string, id:string}}) => {
            if (categories.indexOf(item.attributes.name) === -1) {
              var category = {
                value: item.attributes.id,
                label: item.attributes.name
              };
              allCategories.push(category);
              categories.push(item.attributes.name);
            }
          });

          this.setState({
            AllCategory: responseJson.data,
            allCategories: allCategories
          });
          this.getPostData();
        } else if (apiRequestCallId === this.updatePostApiCallId) {
          this.setState({ refresh: true });
          this.getPostData();
          alert(configJSON.PostUpdated)
          this.props.navigation.goBack();
        } else if (apiRequestCallId === this.DeleteApiCallId) {
          this.getPostData();
        }
      } else if (apiRequestCallId === this.DeleteApiCallId) {
        this.getPostData();
      } else if (errorReponse) {
        this.setState({ refresh: false });
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  createPostCreation() {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
    } else {
      this.AddPostCreation();
    }
  }

  goToItemDetails(item:{attributes: { name:string, id:string}} | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  editNavigation = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const attrs = {
          name: this.state.name,
          description: this.state.description,
          body: this.state.description,
          price: this.state.price,
          currency: "$",
          category_id: this.state.category_id,
          sub_category_id: 1,
          image: this.state.profileImageData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCatergoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(recordId: string) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
            name: this.state.name,
            description: this.state.description,
            body: this.state.description,
            sub_category_id: 1,
            price: this.state.price,
            currency: "$",
            category_id: this.state.category_id,
            image: this.state.profileImageData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${recordId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  deleteRecord(recordId: string) {
    Alert.alert(
      configJSON.Warning,
      configJSON.MessageForDelete,
      [
        { text: "No", onPress: () => {}, style: "cancel" },
        {
          text: "Yes",
          onPress: () => {
            this.setState({ refresh: true });
            this.delete(recordId);
          }
        }
      ],
      { cancelable: false }
    );
  }

  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented");
  };

  handleCatChangeChange = (selectedOption: any) => {
    this.setState({
      selectedCategory: selectedOption,
      category_id: selectedOption?.value
    });
  };

  // Customizable Area End
}
