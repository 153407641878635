import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { Logo, backImage, notValidate, validate } from "./assets";
import { Formik } from "formik";
import { Alert } from '@material-ui/lab';
// Customizable Area End

import SignuploginController, {
  Props,
  configJSON,
} from "./SignuploginController";
import { Visibility, VisibilityOff } from "@material-ui/icons";


export default class Signuplogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderValidatePassword = (password: string) => (
    <div className="validatePasswordWrapper">
      {this.validatePassword.map((item, index) => {
        const isValid = item.regex.test(password);
        return (
          <div
          key={index}
            className="validatePasswordItem"
            style={{
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: '14px'
            }}
          >
            {isValid ? (
              <img
                src={validate}
                alt="checkIcon"
                className="check-icon"
                style={{ marginRight: "8px", height: "14px", width: "14px" }}
              />
            ) : (
              <img
                src={notValidate}
                alt="checkIcon"
                className="check-icon"
                style={{ marginRight: "8px", height: "14px", width: "14px" }}
              />
            )}
            {item.text}
          </div>
        );
      })}
    </div>
  );
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <SignupLoginStyle>
        <Box className="mainContainer">
          <Box className="formContainer">
            <Box className="imageLogo">
              <img src={Logo} alt="Logo" />
            </Box>
            <Formik
            data-test-id="formikSignupForm"
              initialValues={{
                fullName: this.state.signUpData.fullName || "",
                email: this.state.signUpData.email || "",
                password: this.state.signUpData.password || "",
                agreeTerms: false
              }}
              validationSchema={this.signUpSchema}
              onSubmit={values => {
                if (this.checkValidatePassword(values.password)) {
                  this.submitValidateAccount(values.fullName, values.email, values.password);
                }
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <form className="formContainer" onSubmit={handleSubmit}>
                  <Alert 
                  className="alertBox"
                  severity="error" 
                  icon={false}
                  style=
                  {{ 
                    display: touched.agreeTerms && errors.agreeTerms && !values.agreeTerms ? 'block' : 'none', 
                    marginTop: '32px' 
                    }}
                    >
                    You have to agree with Privacy Policy and Terms and Conditions to Sign up.
                  </Alert>
                  <Typography className="signupTitle">Sign up to MyKinships</Typography>
                  <Typography className="signupSubTitle">Start your journey with us.</Typography>
                  <Typography className="titleText">Full name</Typography>
                  <TextField
                    className="placeholderText"
                    value={values.fullName}
                    onChange={handleChange}
                    error={touched.fullName && !!errors.fullName}
                    helperText={touched.fullName && errors.fullName}
                    autoComplete="off"
                    name="fullName"
                    placeholder="Your full name"
                    variant="outlined"
                    fullWidth
                  />
                  <Typography className="titleText">E-mail</Typography>
                  <TextField
                    data-test-id="emailField"
                    className="placeholderText"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    autoComplete="off"
                    name="email"
                    placeholder="Your e-mail"
                    variant="outlined"
                    fullWidth
                  />
                  <Typography className="titleText">Password</Typography>
                  <TextField
                    data-test-id="passwordField"
                    className="placeholderText"
                    value={values.password}
                    onChange={handleChange}
                    error={
                      Boolean(touched.password && errors.password) ||
                      Boolean(this.state.formError.password)
                    }
                    helperText={
                      (touched.password && errors.password) ||
                      this.state.formError.password
                    }
                    autoComplete="off"
                    fullWidth
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                    type={!this.state.showPassword ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            id="passwordConfirmationInput"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            data-test-id="showPasswordIconButton"
                          >
                            {this.renderIconShowPassword(
                              !this.state.showPassword
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {values.password.trim().length > 0 &&
                    this.renderValidatePassword(values.password)}
                  <Box className="conditionContainer">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.agreeTerms}
                          onChange={handleChange} 
                          name="agreeTerms" 
                          style={{ color: values.agreeTerms ? "#557C29" : "#5D6063" }}
                        />
                      }
                      label={
                        <Typography>
                          <span style={{ color: "#1C2110", fontWeight: 400, fontSize: "14px", lineHeight: "22px", fontStyle: "Poppins" }}>I agree with </span>
                          <span style={{ color: "#557C29", fontWeight: 700, fontSize: "14px", lineHeight: "22px", fontStyle: "Poppins" }}>Privacy Policy and</span>
                          <br />
                        </Typography>
                      }
                    />
                    <Typography className="termsTitle">Terms and Conditions</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: this.state.isChecked ? "#557C29" : "#5D6063" }}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label={
                      <Typography className="subscribeCheckBoxTitle">Subscribe to our newsletter.</Typography>
                    }
                  />
                  <Button type="submit" data-test-id="signupButton" className="signupBtn" variant="contained" color="primary" fullWidth >
                    Sign up
                  </Button>

                  <Box className="accountcontainer">
                    <Typography className="accountTextTitle">Already have an account?</Typography>
                    <Button onClick={this.handleNavigateLogin} data-test-id="loginButton" className="loginBtn">Log in</Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </SignupLoginStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SignupLoginStyle = styled("div")({
  height: "100vh",
  width: "100%",
  backgroundImage: `url(${backImage})`,
  backgroundColor: "#305328",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  overflow: "hidden",

  "& .mainContainer": {
    backgroundColor: "white",
    borderRadius: "20px",
    width: "440px",
    padding: '40px',
    overflow: "hidden",
  },
  "& .scrollableContent": {
    maxHeight: "calc(100vh - 160px)",
    overflowY: "auto",
  },
  "& .formContainer": {
    width: '100%',
    height: "100%"
  },
  "& .imageLogo": {
    padding: "0px, 0px, 0px, 40px",
  },
  "& .signupTitle": {
    marginTop: "32px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.5%",
    color: "#1C2110"
  },
  "& .signupSubTitle": {
    marginTop: '8px',
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#202020",
    marginBottom: '16px'
  },
  "& .signupBtn": {
    backgroundColor: '#8CA74B',
    height: "56px",
    borderRadius: "8px",
    padding: "16px",
    Gap: "8px",
    marginTop: "24px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .accountcontainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px"
  },
  "& .titleText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110",
    marginTop: '16px',
    marginBottom: '4px'
  },
  "& .placeholderText": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#94A3B8"
  },
  "& .conditionContainer": {
    marginTop: '32px'
  },
  "& .subscribeCheckBoxTitle": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    color: "#1C2110"
  },
  "& .termsTitle": {
    color: "#557C29",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    marginLeft: '29px'
  },
  "& .accountTextTitle": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110"
  },
  "& .loginBtn": {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#557C29",
    fontStyle: "Poppins"
  },
  "& .validatePasswordWrapper": {
    marginTop: "12px"
  },
  "& .alertBox":{
    borderRadius:'4px',
    border:'0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626', 
    padding:"12px, 16px, 12px, 16px",
    fontSize : "12px",
    foneFamily:"Poppins",
    fontWeight: 400,
  }
});
// Customizable Area End
